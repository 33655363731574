import { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react";
import { Container, CustomButton, CustomModal } from "components/shared";
import { Adds, AddPriceModal, MyBids, MyPersonalInfo } from "components/user";
import { useLocation, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { IoIosArrowDown } from "react-icons/io";
import mybids from "assets/images/mybids.svg";
import mybidsChecked from "assets/images/mybids-checked.svg";
import myads from "assets/images/myads.svg";
import myadsChecked from "assets/images/myads-checked.svg";
import user from "assets/images/user.svg";
import userChecked from "assets/images/user-checked.svg";
import commision from "assets/images/commision.svg";
import commisionChecked from "assets/images/commision.svg";
import { IoIosClose } from "react-icons/io";

const User = () => {
  const options = [" حسابي الشخصي", "إعلاناتي", "مزاياداتي"];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(options[selectedTabIndex]);

  const location = useLocation();
  const navigate = useNavigate();

  // Extract the tab index from the URL when the component mounts
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabIndex = params.get("tab");
    if (tabIndex === "0" || tabIndex === "1" || tabIndex === "2") {
      setSelectedTabIndex(parseInt(tabIndex, 10)); // Valid tab, set the index
    } else {
      setSelectedTabIndex(0); // Invalid tab, reset to default tab 0
      navigate(`${location.pathname}?tab=0`, { replace: true }); // Update URL to reflect default tab
    }
  }, [location]);

  // Update windowWidth state on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle tab change and update the URL with the new tab index
  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
    setSelectedItem(options[index]);
    setOpenMenu(false);
    navigate(`${location.pathname}?tab=${index}`, { replace: true }); // Update the URL
  };

  const onClickOutSide = () => {
    setOpenMenu(false);
  };

  document.body.onkeydown = (e) => {
    if (e.key == "Escape") {
      onClickOutSide();
    }
  };

  return (
    <Container>
      <Box
        border="1px solid rgba(0,0,0,.125)"
        borderRadius="5px"
        pb="20px"
        mb="50px">
        <Tabs
          variant="unstyled"
          index={selectedTabIndex} // Set the active tab from state
          onChange={handleTabChange} // Handle tab change and update URL
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            py="10px"
            px="15px"
            bg="rgba(0,0,0,.03)"
            borderBottom="1px solid rgba(0,0,0,.125)"
            mb="20px"
            flexWrap={["wrap", "nowrap", "", ""]}>
            {windowWidth >= 768 ? (
              <TabList pos="relative" w="70%" gap="10px">
                <Tab
                  px={["12px", "4", "", ""]}
                  fontSize={["14px", "16px", "", ""]}
                  bg="white"
                  borderRadius="30px"
                  color="gray"
                  _selected={{
                    border: "1px solid #1D85F4",
                    color: "black",
                  }}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Image
                      src={selectedTabIndex == 0 ? userChecked : user}
                      me="5px"
                    />
                    <Text fontWeight="bold">حسابي الشخصي</Text>
                  </Flex>
                </Tab>
                <Tab
                  px={["12px", "4", "", ""]}
                  fontSize={["14px", "16px", "", ""]}
                  bg="white"
                  borderRadius="30px"
                  color="gray"
                  _selected={{
                    border: "1px solid #1D85F4",
                    color: "black",
                  }}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Image
                      src={selectedTabIndex == 1 ? myadsChecked : myads}
                      me="5px"
                    />
                    <Text fontWeight="bold">إعلاناتي</Text>
                  </Flex>
                </Tab>
                <Tab
                  px={["12px", "4", "", ""]}
                  fontSize={["14px", "16px", "", ""]}
                  bg="white"
                  borderRadius="30px"
                  color="gray"
                  _selected={{
                    border: "1px solid #1D85F4",
                    color: "black",
                  }}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Image
                      src={selectedTabIndex == 2 ? mybidsChecked : mybids}
                      me="5px"
                    />
                    <Text fontWeight="bold">مزاياداتي </Text>
                  </Flex>
                </Tab>
              </TabList>
            ) : (
              <TabList pos="relative">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    onClickOutSide();
                  }}>
                  <Flex
                    cursor="pointer"
                    justifyContent="space-between"
                    alignItems="center"
                    bg="white"
                    borderRadius="30px"
                    border="1px solid #1D85F4"
                    p="8px"
                    px="20px"
                    onClick={() => setOpenMenu(true)}>
                    <Image
                      src={
                        selectedTabIndex == 0
                          ? userChecked
                          : selectedTabIndex == 1
                          ? myadsChecked
                          : mybidsChecked
                      }
                      me="5px"
                    />
                    <Text fontWeight="bold">{options[selectedTabIndex]}</Text>
                    {openMenu ? (
                      <Box
                        fontSize="24px"
                        as={IoIosClose}
                        ms="10px"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenMenu(false);
                        }}
                      />
                    ) : (
                      <Box as={IoIosArrowDown} ms="10px" />
                    )}
                  </Flex>

                  {openMenu ? (
                    <Box
                      w="180px"
                      bg="white"
                      borderRadius="5px"
                      p="10px"
                      position="absolute"
                      border="1px solid #e7e3e3"
                      top="45px"
                      zIndex="11">
                      <Tab
                        justifyContent="start"
                        w="100%"
                        px={["12px", "4", "", ""]}
                        fontSize={["14px", "16px", "", ""]}
                        bg="white"
                        borderRadius="30px"
                        _selected={{
                          bg: "#E8F3FE",
                        }}>
                        <Flex alignItems="center">
                          <Image
                            src={selectedTabIndex == 0 ? userChecked : user}
                            me="5px"
                          />
                          <Text color="#2c5282">حسابي الشخصي </Text>
                        </Flex>
                      </Tab>
                      <Tab
                        my="15px"
                        justifyContent="start"
                        w="100%"
                        px={["12px", "4", "", ""]}
                        fontSize={["14px", "16px", "", ""]}
                        bg="white"
                        borderRadius="30px"
                        _selected={{
                          bg: "#E8F3FE",
                        }}>
                        <Flex alignItems="center">
                          <Image
                            src={selectedTabIndex == 1 ? myadsChecked : myads}
                            me="5px"
                          />
                          <Text color="#2c5282">إعلاناتي </Text>
                        </Flex>
                      </Tab>
                      <Tab
                        justifyContent="start"
                        w="100%"
                        px={["12px", "4", "", ""]}
                        fontSize={["14px", "16px", "", ""]}
                        bg="white"
                        borderRadius="30px"
                        _selected={{
                          bg: "#E8F3FE",
                        }}>
                        <Flex alignItems="center">
                          <Image
                            src={selectedTabIndex == 2 ? mybidsChecked : mybids}
                            me="5px"
                          />
                          <Text color="#2c5282">مزاياداتي </Text>
                        </Flex>
                      </Tab>

                      <Flex
                        cursor="pointer"
                        alignItems="center"
                        mt="15px"
                        px={["12px", "4", "", ""]}
                        onClick={() => {
                          setOpenMenu(false);
                          setShowModal(true);
                        }}>
                        <Image src={commisionChecked} me="5px" />
                        <Text color="#2c5282">حساب العمولة </Text>
                      </Flex>
                    </Box>
                  ) : null}
                </OutsideClickHandler>
              </TabList>
            )}
            <Box display={["none", "block", "block", ""]}>
              <CustomButton
                name="حساب العمولة"
                onClick={() => setShowModal(true)}
              />
            </Box>
          </Flex>

          <TabPanels px="15px">
            <TabPanel>
              <MyPersonalInfo />
            </TabPanel>

            <TabPanel>
              <Adds />
            </TabPanel>
            <TabPanel>
              <MyBids />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* <Box px="15px">
          <Adds />
        </Box> */}

        <CustomModal
          show={showModal}
          setShow={setShowModal}
          title="حساب العمولة">
          <AddPriceModal isShow />
        </CustomModal>
      </Box>
    </Container>
  );
};

export default User;
