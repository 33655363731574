// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyCVda959unH2qSupu80lQhIMtxI6bkpOWw",
  authDomain: "aerbag-flutter.firebaseapp.com",
  databaseURL: "https://aerbag-flutter.firebaseio.com",
  projectId: "aerbag-flutter",
  storageBucket: "aerbag-flutter.appspot.com",
  messagingSenderId: "196600744174",
  appId: "1:196600744174:web:0b138b872ce150b4514bff",
  measurementId: "G-YYZ9Y3NHYT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

const publicKey =
  "BD4NDjmZ2_GVXwSRITRv4_O_qUIGbMrbdx4bSK81Qx7hEs9Tlet6-K4JmXPA1TTqobAHSRhnmqvd_IXCDywqTlI";

export function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, { vapidKey: publicKey })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("fcmToken", currentToken);
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
