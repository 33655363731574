import { useState } from "react";
import { Box, Grid, Text } from "@chakra-ui/react";
import { getBids } from "apis/auctions";
import { Loading, Pagination } from "components/shared";
import moment from "moment";
import { useQuery } from "react-query";
import AuctionCard from "./adds/auction-card";

const MyBids = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading } = useQuery(["get my bids", pageNumber], getBids);

  if (isLoading) {
    return (
      <Grid placeItems="center" minH="40vh">
        <Loading />
      </Grid>
    );
  }

  let itemsPerPage = data?.data.meta.per_page;
  let currentPage = data?.data.meta.current_page;
  let itemCount = data?.data.meta.to;
  let totalItems = data?.data.meta.total;
  let numberOfPages = totalItems / itemsPerPage;

  const handlePageClick = async (data) => {
    setPageNumber(data.selected + 1);
    window.scrollTo(0, 30);
  };

  if (data?.data?.data?.length == 0) {
    return (
      <Box>
        <Text textAlign="center" fontSize="18px" py="30px" opacity="0.8">
          {" "}
          لم تقم بأى مزايادات بعد{" "}
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Grid
        gridTemplateColumns={[
          "repeat(1,1fr)",
          "repeat(2,1fr)",
          "repeat(3,1fr)",
          "repeat(3,1fr)",
        ]}
        gap="15px">
        {data?.data?.data?.map((e) => {
          return (
            <AuctionCard
              key={Math.random()}
              date={moment(e?.created_at).format("yyyy-MM-DD")}
              serial={e?.serial}
              id={e?.id}
              status={e?.status}
              is_hidden={e?.is_hidden}
              is_sold={e?.is_sold}
              numbers={e?.numbers}
              chars_ar={e?.chars_ar}
              chars_en={e?.chars_en}
              type={e?.type}
            />
          );
        })}
      </Grid>

      <Box mt="15px">
        <Pagination
          handlePageClick={handlePageClick}
          numberOfPages={numberOfPages}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          itemCount={itemCount}
          totalItems={totalItems}
        />
      </Box>
    </Box>
  );
};

export default MyBids;
