import { Box, Text, Image } from "@chakra-ui/react";
import currency from "assets/images/Saudi_Currency_bg.svg";

const TextCard = ({ text, value, textIsHidden, isPrice }) => {
  return (
    <Box border="1px solid #c6c6c6" boxShadow="0 0 19px 0px #ccc" p="15px">
      <Text textAlign="center" mb="25px">
        {text}
      </Text>

      <Text
        fontWeight="bold"
        color="#137fce"
        fontSize="19px"
        textAlign="center"
      >
        {textIsHidden != 1 ? (
          isPrice ? (
            <>
              {value}

              <Image
                src={currency}
                w="20px"
                display="inline-block"
                ms="2px"
                pos="relative"
                transform="translateY(5px)"
              />
            </>
          ) : (
            value
          )
        ) : (
          "التواصل من خلال التطبيق"
        )}
      </Text>
    </Box>
  );
};

export default TextCard;

{
}
