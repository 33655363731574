import { useState, useContext, useEffect } from "react";
import { selectionsContext } from "contexts/selections";
import isFilter from "contexts/filter";
import { Box, Text, Grid } from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { Container, CustomSelect } from "components/shared";
import CustomButton from "components/shared/button";
import { Formik, Form } from "formik";
// import {
//   typeSelectionFilter,
//   numberTypeSelections,
//   letters,
//   numbers,
// } from "components/shared/selections";

const SearchLuhah = ({
  selectType,
  setSelectType,
  selectNumberType,
  setSelectNumberType,
  selectFirstLetter,
  setSelectFirstLetter,
  selectSecondLetter,
  setSelectSecondLetter,
  selectThirdLetter,
  setSelectThirdLetter,
  setSelectNumberOne,
  setSelectNumberTwo,
  setSelectNumberThree,
  setSelectNumberFour,
  typeSelections,
  numberTypeSelections,
  charsSelections,
  number_selections,
}) => {
  const { filterData, setFilterData } = useContext(isFilter);
  const [removeSearch, setRemoveSearch] = useState(filterData.isFilterExist);

  const all = { name: "الكل", id: "" };

  const [fieldsNumbers, setFieldsNumbers] = useState(all);
  const [num1, setNum1] = useState(all);
  const [num2, setNum2] = useState(all);
  const [num3, setNum3] = useState(all);
  const [num4, setNum4] = useState(all);

  useEffect(() => {
    if (filterData.isFilterExist) {
      setSelectType(filterData.type);
      setSelectNumberType(filterData.numberType);
      setSelectFirstLetter(filterData.letterOne);
      setSelectSecondLetter(filterData.letterTwo);
      setSelectThirdLetter(filterData.letterThree);
      setFieldsNumbers(filterData.numberType);
      setSelectNumberOne(filterData.numberOne);
      setSelectNumberTwo(filterData.numberTwo);
      setSelectNumberThree(filterData.numberThree);
      setSelectNumberFour(filterData.numberFour);
      setNum1(filterData.numberOne);
      setNum2(filterData.numberTwo);
      setNum3(filterData.numberThree);
      setNum4(filterData.numberFour);
    }
  }, []);

  const handleSelectType = (e, formik) => {
    formik.setFieldValue("type", e);
  };

  const handleSelectNumberType = (e, formik) => {
    setFieldsNumbers(e);
    setNum1(all);
    setNum2(all);
    setNum3(all);
    setNum4(all);

    formik.setFieldValue("numberType", e);
    formik.setFieldValue("numberOne", all);
    formik.setFieldValue("numberTwo", all);
    formik.setFieldValue("numberThree", all);
    formik.setFieldValue("numberFour", all);
  };

  //handle select letters
  const handleSelectFirstLetter = (e, formik) => {
    formik.setFieldValue("letterOne", e);
  };

  const handleSelectSecondLetter = (e, formik) => {
    formik.setFieldValue("letterTwo", e);
  };

  const handleSelectThirdLetter = (e, formik) => {
    formik.setFieldValue("letterThree", e);
  };

  //handle select numbers
  const handleSelectNumberOne = (e, formik) => {
    formik.setFieldValue("numberOne", e);
    setNum1(e);
  };

  const handleSelectNumberTwo = (e, formik) => {
    formik.setFieldValue("numberTwo", e);
    setNum2(e);
  };

  const handleSelectNumberThree = (e, formik) => {
    formik.setFieldValue("numberThree", e);
    setNum3(e);
  };

  const handleSelectNumberFour = (e, formik) => {
    formik.setFieldValue("numberFour", e);
    setNum4(e);
  };

  return (
    <Box
      bg="radial-gradient(ellipse at center, #21a3fd 0%, #0473c0 100%)"
      py="32px"
      mb="30px">
      <Container>
        <Text
          pb="15px"
          textAlign="center"
          fontSize="19px"
          fontWeight="bold"
          color="white"
          borderBottom="1px solid white"
          mb="32px">
          تدور لوحة معينة؟ أبحث عنها هنا
        </Text>
        <Formik
          initialValues={{
            type: filterData?.type || all,
            numberType: filterData?.numberType || all,
            letterOne: filterData?.letterOne || all,
            letterTwo: filterData?.letterTwo || all,
            letterThree: filterData?.letterThree || all,
            numberOne: filterData?.numberOne || all,
            numberTwo: filterData?.numberTwo || all,
            numberThree: filterData?.numberThree || all,
            numberFour: filterData?.numberFour || all,
          }}
          enableReinitialize
          // validationSchema={OtpValidation}
          onSubmit={(values) => {
            setSelectType(values.type);
            setSelectNumberType(values.numberType);
            setSelectFirstLetter(values.letterOne);
            setSelectSecondLetter(values.letterTwo);
            setSelectThirdLetter(values.letterThree);
            setSelectNumberOne(values.numberOne);
            setSelectNumberTwo(values.numberTwo);
            setSelectNumberThree(values.numberThree);
            setSelectNumberFour(values.numberFour);
            setRemoveSearch(true);
            setFilterData({
              isFilterExist: true,
              type: values.type,
              numberType: values.numberType,
              letterOne: values.letterOne,
              letterTwo: values.letterTwo,
              letterThree: values.letterThree,
              numberOne: values.numberOne,
              numberTwo: values.numberTwo,
              numberThree: values.numberThree,
              numberFour: values.numberFour,
            });
          }}>
          {(formik) => {
            return (
              <Form>
                <Grid
                  gridTemplateColumns="repeat(2,1fr)"
                  gap={["10px", "25px", "", ""]}
                  mb="15px">
                  <CustomSelect
                    label="نوع اللوحة"
                    placeholder=""
                    select={selectType}
                    handleSelect={(e) => handleSelectType(e, formik)}
                    selections={typeSelections}
                  />

                  <CustomSelect
                    label="نوع الارقام"
                    placeholder=""
                    select={selectNumberType}
                    handleSelect={(e) => handleSelectNumberType(e, formik)}
                    selections={numberTypeSelections}
                  />
                </Grid>

                <Grid
                  gridTemplateColumns="repeat(3,1fr)"
                  gap={["10px", "25px", "", ""]}
                  mb="15px">
                  <CustomSelect
                    label="الحرف الاول"
                    placeholder=""
                    select={selectFirstLetter}
                    handleSelect={(e) => handleSelectFirstLetter(e, formik)}
                    selections={charsSelections}
                  />
                  <CustomSelect
                    label="الحرف الثاني"
                    placeholder=""
                    select={selectSecondLetter}
                    handleSelect={(e) => handleSelectSecondLetter(e, formik)}
                    selections={charsSelections}
                  />
                  <CustomSelect
                    label="الحرف الثالث"
                    placeholder=""
                    select={selectThirdLetter}
                    handleSelect={(e) => handleSelectThirdLetter(e, formik)}
                    selections={charsSelections}
                  />
                </Grid>

                <Grid
                  gridTemplateColumns={
                    fieldsNumbers.id == "" || fieldsNumbers.id == 4
                      ? ["repeat(4,1fr)", "", "repeat(4,1fr)", ""]
                      : fieldsNumbers.id == 1
                      ? "repeat(1,fr)"
                      : fieldsNumbers.id == 2
                      ? "repeat(2,1fr)"
                      : "repeat(3,1fr)"
                  }
                  gap={
                    fieldsNumbers.id == "" || fieldsNumbers.id == 4
                      ? ["5px", "25px", "", ""]
                      : ["10px", "25px", "", ""]
                  }
                  fontSize={
                    fieldsNumbers.id == "" || fieldsNumbers.id == 4
                      ? ["15px", "16px", "", ""]
                      : "16px"
                  }
                  mb="40px">
                  {fieldsNumbers.id == "" || fieldsNumbers.id == 4 ? (
                    <>
                      <CustomSelect
                        label="الرقم الرابع"
                        placeholder=""
                        select={num4}
                        handleSelect={(e) => handleSelectNumberFour(e, formik)}
                        selections={number_selections}
                      />
                      <CustomSelect
                        label="الرقم الثالث"
                        placeholder=""
                        select={num3}
                        handleSelect={(e) => handleSelectNumberThree(e, formik)}
                        selections={number_selections}
                      />
                      <CustomSelect
                        label="الرقم الثاني"
                        placeholder=""
                        select={num2}
                        handleSelect={(e) => handleSelectNumberTwo(e, formik)}
                        selections={number_selections}
                      />
                      <CustomSelect
                        label="الرقم الاول"
                        placeholder=""
                        select={num1}
                        handleSelect={(e) => handleSelectNumberOne(e, formik)}
                        selections={number_selections}
                      />
                    </>
                  ) : fieldsNumbers.id == 1 ? (
                    <CustomSelect
                      label="الرقم الاول"
                      placeholder=""
                      select={num1}
                      handleSelect={(e) => handleSelectNumberOne(e, formik)}
                      selections={number_selections}
                    />
                  ) : fieldsNumbers.id == 2 ? (
                    <>
                      <CustomSelect
                        label="الرقم الثاني"
                        placeholder=""
                        select={num2}
                        handleSelect={(e) => handleSelectNumberTwo(e, formik)}
                        selections={number_selections}
                      />
                      <CustomSelect
                        label="الرقم الاول"
                        placeholder=""
                        select={num1}
                        handleSelect={(e) => handleSelectNumberOne(e, formik)}
                        selections={number_selections}
                      />
                    </>
                  ) : (
                    <>
                      <CustomSelect
                        label="الرقم الثالث"
                        placeholder=""
                        select={num3}
                        handleSelect={(e) => handleSelectNumberThree(e, formik)}
                        selections={number_selections}
                      />
                      <CustomSelect
                        label="الرقم الثاني"
                        placeholder=""
                        select={num2}
                        handleSelect={(e) => handleSelectNumberTwo(e, formik)}
                        selections={number_selections}
                      />
                      <CustomSelect
                        label="الرقم الاول"
                        placeholder=""
                        select={num1}
                        handleSelect={(e) => handleSelectNumberOne(e, formik)}
                        selections={number_selections}
                      />
                    </>
                  )}
                </Grid>

                {removeSearch && (
                  <CustomButton
                    mb="15px"
                    name="إزالة البحث"
                    bg="#992727"
                    icon={IoClose}
                    onClick={() => {
                      formik.setValues({
                        type: all,
                        numberType: all,
                        letterOne: all,
                        letterTwo: all,
                        letterThree: all,
                        numberOne: all,
                        numberTwo: all,
                        numberThree: all,
                        numberFour: all,
                      });

                      setSelectType(all);
                      setSelectNumberType(all);
                      setFieldsNumbers(all);
                      setNum1(all);
                      setNum2(all);
                      setNum3(all);
                      setNum4(all);
                      setSelectFirstLetter(all);
                      setSelectSecondLetter(all);
                      setSelectThirdLetter(all);
                      setSelectNumberOne(all);
                      setSelectNumberTwo(all);
                      setSelectNumberThree(all);
                      setSelectNumberFour(all);
                      setRemoveSearch(false);
                      setFilterData({
                        isFilterExist: false,
                      });
                    }}
                  />
                )}

                <CustomButton
                  type="submit"
                  name="بحث"
                  bg="linear-gradient(
                      45deg,
                      rgb(249, 198, 103) 0%,
                      rgb(247, 150, 33) 100%
                    )"
                  icon={BsSearch}
                  disabled={!formik.dirty}
                  _hover={{}}
                  _disabled={{ opacity: "0.8", cursor: "not-allowed" }}
                />
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Box>
  );
};

export default SearchLuhah;
