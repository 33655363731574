import { useRef, useState, useEffect, useContext } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
} from "@chakra-ui/react";

import { userContext } from "contexts/user";
import { Container, CustomButton } from "components/shared";

import { NavLink, Link } from "react-router-dom";

import { IoMdNotifications } from "react-icons/io";
import { useQuery, useMutation } from "react-query";
import { getNotifications, updateNotification } from "apis/notifications";
import { getMessaging, onMessage } from "firebase/messaging";

const Notifications = ({ radius }) => {
  const [notificationsNotReaded, setNotificationNotReaded] = useState([]);
  const [auctionNotifications, setAuctionNotifications] = useState([]);

  const { data, loading, refetch } = useQuery(
    "get notifications",
    getNotifications,
    {
      onSuccess: (res) => {
        const notReaded = res?.data?.data?.filter((e) => {
          return e?.is_read == 0;
        });

        setNotificationNotReaded(notReaded);

        const auctionNotifications = res?.data?.data?.filter((e) =>
          [24, 26, 27, 28, 29, 30].includes(e?.action_type)
        );

        setAuctionNotifications(auctionNotifications);
      },
    }
  );

  const { mutate } = useMutation(updateNotification, {
    onSuccess: () => {
      refetch();
    },
  });

  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    refetch();
  });

  return (
    <>
      <Menu onClose={mutate}>
        <MenuButton as={Button} p="0" bg="transparent" _hover={{}} _active={{}}>
          <Grid
            as={Link}
            to="/favorites"
            placeItems="center"
            borderRadius={radius ? "50%" : "10px"}
            p="14px"
            cursor="pointer"
            bg="#ececec"
            pos="relative"
          >
            <Box as={IoMdNotifications} />

            {data?.data?.data ? (
              <>
                {notificationsNotReaded?.length == 0 ? null : (
                  <Grid
                    placeItems="center"
                    w="18px"
                    h="18px"
                    bg="red"
                    borderRadius="50%"
                    pos="absolute"
                    top="-5px"
                    right="-5px"
                    color="white"
                    fontSize="10px"
                    userSelect="none"
                  >
                    {notificationsNotReaded?.length}
                  </Grid>
                )}
              </>
            ) : null}
          </Grid>
        </MenuButton>
        <MenuList
          maxH="450px"
          overflow="auto"
          w={["100%", "400px", "400px", "400px"]}
        >
          {auctionNotifications?.length == 0 ? (
            <Text my="50px" textAlign="center" color="gray">
              لا يوجد لديك أى إشعارات فائتة
            </Text>
          ) : (
            <>
              {auctionNotifications?.map((e, i) => {
                return (
                  <MenuItem
                    value={i}
                    as={Link}
                    to={`/show-auctions/${e?.action_id}`}
                    borderBottom={
                      i == data?.data?.data?.length - 1
                        ? "0"
                        : "1px solid #e2e2e2"
                    }
                    cursor="pointer"
                    py="15px"
                    borderRadius="0"
                  >
                    <Flex
                      gap="5px"
                      alignItems="center"
                      justifyContent="center"
                      w="100%"
                    >
                      <Box
                        w="10px"
                        h="10px"
                        borderRadius="50%"
                        bg={e?.is_read == 0 ? "#17C553" : "#c0c0c0"}
                      />

                      <Box w="calc(100% - 10px)">
                        <Flex justifyContent="space-between">
                          <Text fontWeight="bold">{e?.data?.title_ar} </Text>
                          <Text dir="ltr">{e?.created_at}</Text>
                        </Flex>
                        <Text> {e?.data?.body_ar}</Text>
                        <Text>
                          <Text fontWeight="bold" as="span">
                            {" "}
                            Auction ID
                          </Text>{" "}
                          ({e?.action_id})
                        </Text>
                      </Box>
                    </Flex>
                  </MenuItem>
                );
              })}
            </>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default Notifications;
