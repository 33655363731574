import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { useContext } from "react";
import { userContext } from "contexts/user";

const MyPersonalInfo = () => {
  const user = JSON.parse(localStorage.getItem("lu7ah_user"));

  return (
    <Box>
      <Flex
        gap="15px"
        flexDir={["column", "row", "", ""]}
        alignItems={["center", "initial", "", ""]}>
        <Image src={user?.avatar} w="150px" h="150px" borderRadius="10px" />
        <Box>
          <Text mb="10px">
            <Text as="span" fontWeight="bold">
              الاسم:{" "}
            </Text>
            {user?.name}
          </Text>

          <Text mb="10px">
            {" "}
            <Text as="span" fontWeight="bold">
              الإيميل:{" "}
            </Text>{" "}
            {user?.email}
          </Text>

          <Text mb="10px">
            {" "}
            <Text as="span" fontWeight="bold">
              الهاتف:{" "}
            </Text>{" "}
            {user?.mobile}
          </Text>

          <Text>
            {" "}
            <Text as="span" fontWeight="bold">
              تاريخ الانضمام:{" "}
            </Text>{" "}
            {user?.created_date}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default MyPersonalInfo;
