import { Box, Text, useToast, Flex, Image } from "@chakra-ui/react";
import { createBid, joinAsBidder } from "apis/auctions";
import { CustomButton, CustomToast } from "components/shared";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import currency from "assets/images/Saudi_Currency.svg";

const GoToPayModal = ({ auctionId, auctionEndAt, topBid }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutate, isSuccess, isLoading } = useMutation(
    "join-as-bidder",
    joinAsBidder,
    {
      onSuccess: (e) => {
        navigate("/pay", {
          state: {
            urlRequest: e?.data?.redirect_url,
            checkout_details: e?.data?.checkout_details,
          },
        });
      },
      onError: (err) => {
        const errorMessage =
          err?.response?.data?.message || "حدث خطأ ما، برجاء المحاولة لاحقاً";

        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"دفع العمولة"}
                description={errorMessage}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box>
      <Text mb="20px">
        للدخول في هذا المزاد، يجب دفع عربون بقيمة 115{" "}
        <Image src={currency} display="inline" w="15px" /> لمرة واحدة، وسيتم
        استرداده عند انتهاء المزاد. لمعرفة المزيد، راجع سياسة الإعلانات الخاصة
        بمنصة{" "}
        <Text as="span" fontWeight="bold">
          LU
          <Text as="span" fontWeight="bold" color="text.darkBlue">
            7
          </Text>
          AH
        </Text>
      </Text>

      <Flex justifyContent="space-between" alignItems="center" mb="20px">
        <Text>ينتهي المزاد في</Text>
        <Text fontWeight="bold">
          {moment(auctionEndAt).format("hh")}h:
          {moment(auctionEndAt).format("mm")}m:
          {moment(auctionEndAt).format("ss")}s{" "}
        </Text>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mb="40px">
        <Text> أعلى مزايدة </Text>
        <Text fontWeight="bold">
          {" "}
          {topBid}{" "}
          <sub>
            {" "}
            <Image src={currency} display="inline" w="15px" />
          </sub>
        </Text>
      </Flex>

      <CustomButton
        name="الدخول للمزاد"
        borderRadius="30px"
        m="auto"
        loading={isLoading}
        onClick={() => {
          mutate({
            after_completion_action: `${process.env.REACT_APP_BASE_URL}/show-auctions/${auctionId}`,
            auctionId,
          });
        }}
      />
    </Box>
  );
};

export default GoToPayModal;
