import { useState, useEffect, useContext } from "react";
import { Box, Grid, Text, Spinner, Button, Flex } from "@chakra-ui/react";
import { LuhahAuctionCard } from "components/auctions";
import { Container, CustomSelect, Loading } from "components/shared";
import { sortingSelections } from "components/shared/selections";

import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { getAuctions } from "apis/auctions";
import { selectionsContext } from "contexts/selections";

const Auctions = () => {
  const all = { name: "الكل", id: "" };

  const { ref, inView } = useInView();
  const [sortingByDate, setSortingByDate] = useState(sortingSelections[0]);
  const [selectType, setSelectType] = useState(all);

  const handleSortingByDate = (e) => {
    setSortingByDate(e);
  };
  const handleSelectType = (e, formik) => {
    setSelectType(e);
  };

  const selections = useContext(selectionsContext);
  const type = selections?.type;

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["getAllListings", selectType?.id, sortingByDate.id],
    getAuctions,
    {
      // getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
      // getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,

      getNextPageParam: (_lastPage, pages) => {
        if (pages.length < pages[0]?.data?.meta?.last_page) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <Box mb="50px">
      <Container>
        <Flex
          gap="20px"
          alignItems="center"
          mb="30px"
          flexWrap={["wrap", "nowrap", "", ""]}>
          <Box w={["100%", "initial", "", ""]}>
            <CustomSelect
              label="الترتيب"
              colorLabel="black"
              placeholder=""
              select={sortingByDate}
              handleSelect={handleSortingByDate}
              selections={sortingSelections}
            />
          </Box>

          <Box w={["100%", "200px", "", ""]}>
            <CustomSelect
              label="نوع اللوحة"
              colorLabel="black"
              placeholder=""
              select={selectType}
              handleSelect={handleSelectType}
              selections={[all, ...type]}
            />
          </Box>
        </Flex>

        {isLoading ? (
          <Grid placeItems="center" my="50px">
            <Loading />
          </Grid>
        ) : data?.pages[0]?.data?.data.length > 0 ? (
          <>
            <Grid
              gridTemplateColumns={[
                "repeat(1,1fr) ",
                "repeat(2,1fr) ",
                "repeat(3,1fr) ",
                "repeat(3,1fr) ",
              ]}
              gap="20px">
              {data?.pages?.map((p) => {
                return (
                  <>
                    {p?.data?.data?.map((e) => (
                      <Box key={e.id}>
                        <LuhahAuctionCard
                          startTime={e?.auction_info?.auction_start_at}
                          endDate={e?.auction_info?.auction_end_at}
                          bids={e?.auction_info?.bids}
                          id={e?.id}
                          charsAr={e?.chars_ar}
                          charsEn={e?.chars_en}
                          numbers={e?.numbers}
                          type={e?.type}
                          date={e?.created_at}
                          price={e?.price}
                        />
                      </Box>
                    ))}
                  </>
                );
              })}
            </Grid>

            {hasNextPage ? (
              <Button
                ref={ref}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage}
                minH="38px"
                maxH="100%"
                fontWeight="normal"
                bg="bg.darkBlue"
                color="white"
                border="none"
                borderRadius="5px"
                w="100%"
                _hover={{}}
                _focus={{ outline: "none" }}
                _active={{}}
                display="flex"
                alignItems="center"
                my="30px">
                {isFetchingNextPage ? <Spinner me="5px" /> : null}
                تحميل المزيد
              </Button>
            ) : null}
          </>
        ) : (
          <Text textAlign="center" fontSize="20px" my="50px">
            لا يوجد بيانات لعرضها
          </Text>
        )}
      </Container>
    </Box>
  );
};

export default Auctions;
