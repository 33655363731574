import { Box, Flex, Text, Grid, Image } from "@chakra-ui/react";
import { CustomButton, Luhah as L } from "components/shared";
import { Link } from "react-router-dom";
import moment from "moment";
import currency from "assets/images/Saudi_Currency.svg";

const Luhah = ({
  isAuction,
  charsAr,
  charsEn,
  numbers,
  location,
  date,
  price,
  underBidding,
  id,
  type,
}) => {
  const Info = ({ name, value, isAuction, isPrice, ...props }) => {
    return (
      <Box bg="#f7f7f7" p="5px" display="flex" alignItems="center" {...props}>
        {isAuction ? (
          <Text>
            مزاد
            <Text as="span" fontWeight="bold">
              {" "}
              LU
              <Text as="span" fontWeight="bold" color="text.darkBlue">
                7
              </Text>
              AH
            </Text>
          </Text>
        ) : (
          <>
            {isPrice ? (
              <Flex gap="5px">
                <Text fontSize="15px">
                  {name}{" "}
                  <Text as="span" fontWeight="bold">
                    {value}
                  </Text>
                </Text>
                <Image src={currency} w="15px" />
              </Flex>
            ) : (
              <Text fontSize="15px">
                {name}{" "}
                <Text as="span" fontWeight="bold">
                  {value}
                </Text>
              </Text>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Flex
      alignItems="center"
      boxShadow="0 0 10px 0 #ccc"
      p="10px"
      flexWrap="wrap"
    >
      <L
        charsEn={charsEn}
        charsAr={charsAr}
        numbers={numbers}
        type={type}
        w="100%"
        me="0"
      />

      <Grid gridTemplateColumns="repeat(2,2fr)" gap="15px" mt="15px" w="100%">
        <Info name="المدينة" value={location} />
        <Info name="التاريخ" value={moment(date).format("yyyy-MM-DD")} />
        <Info
          name="السعر"
          value={underBidding == 1 ? "على السوم" : `${price}  `}
          isAuction={isAuction}
          isPrice={underBidding != 1}
        />

        <CustomButton
          as={Link}
          to={isAuction ? `show-auctions/${id}` : `show-adds/${id}`}
          name="مشاهدة الإعلان"
          w="auto"
          display="grid"
          h="100%"
          placeItems="center"
        />
      </Grid>
    </Flex>
  );
};

export default Luhah;
