import { Box, Flex, Text, Grid, Image } from "@chakra-ui/react";
import { CustomButton, Luhah as L } from "components/shared";
import { Link } from "react-router-dom";
import CountdownTimer from "./counter-down";
import moment from "moment";
import currency from "assets/images/Saudi_Currency_bg.svg";

const LuhahAuctionCard = ({
  charsAr,
  charsEn,
  numbers,
  startTime,
  endDate,

  date,
  price,
  bids,
  id,
  type,
}) => {
  const Info = ({ name, value, ...props }) => {
    return (
      <Box
        bg="#f7f7f7"
        p="5px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        {...props}
      >
        <Text fontSize="15px">{name}</Text>

        <Text as="span" fontWeight="bold">
          {value}
        </Text>
      </Box>
    );
  };

  return (
    <Box as={Link} to={`/show-auctions/${id}`}>
      <Box
        alignItems="center"
        boxShadow="0 0 10px 0 #ccc"
        borderRadius="10px"
        transition="0.3s"
        _hover={{ transform: "scale(1.03)" }}
      >
        <Box p="10px">
          <Text
            fontSize={["16px", "18px", "18px", "18px"]}
            textAlign="center"
            color="gray"
            fontWeight="bold"
          >
            الوقت المتبقى حتى نهاية المزاد
          </Text>
          <CountdownTimer endDate={endDate} startTime={startTime} />
          <L
            charsEn={charsEn}
            charsAr={charsAr}
            numbers={numbers}
            type={type}
            w="100%"
            me="0"
          />

          <Grid
            gridTemplateColumns="repeat(2,2fr)"
            gap="15px"
            mt="15px"
            w="100%"
          >
            <Info name="عدد المزايادات" value={bids || 0} />
            <Info name="التاريخ" value={moment(date).format("yyyy-MM-DD")} />
          </Grid>
        </Box>

        <Box borderTop="2px solid #dbdbdb" mt="20px" py="20px" px="10px">
          <Text
            fontSize={["16px", "18px", "18px", "18px"]}
            textAlign="center"
            color="gray"
            fontWeight="bold"
          >
            مبلغ اعلى مزايدة
          </Text>
          <Flex gap="5px" alignItems="center" justifyContent="center">
            <Text
              fontSize={["30px", "36px", "36px", "36px"]}
              textAlign="center"
              color="text.darkBlue"
              fontWeight="bold"
            >
              {price}
            </Text>
            <Image src={currency} w="25px" />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default LuhahAuctionCard;
