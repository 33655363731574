import { useState, useEffect, useContext } from "react";
import {
  Box,
  Spinner,
  Text,
  useToast,
  PinInputField,
  PinInput,
  Stack,
  Grid,
  Flex,
} from "@chakra-ui/react";
import {
  Container,
  CustomInput,
  CustomButton,
  ErrorMsg,
  CustomToast,
} from "components/shared";
import { userContext } from "contexts/user";
import { Formik, Form } from "formik";
import { OtpValidation } from "validations/confirm-otp";

import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { signin, getUserData, verifyOtp } from "apis/user";

const ConfirmOtp = ({ userPhone }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const { setToken } = useContext(userContext);

  const { refetch, isLoading: loadingData } = useQuery(
    "get-user-data",
    getUserData,
    {
      enabled: false,
      onSuccess: (res) => {
        localStorage.setItem("lu7ah_user", JSON.stringify(res?.data?.customer));
        navigate("/");
      },
    }
  );

  const { mutate: mutateVerify, isLoading: isLoadingVerify } = useMutation(
    verifyOtp,
    {
      onSuccess: (res) => {
        if (!res?.data?.user?.is_profile_ready) {
          toast({
            render: (props) => {
              return (
                <CustomToast
                  title={"لا يمكن الدخول"}
                  description="حسابك غير مكتمل برجاء اكمال البيانات، استخدم تطبيق لوحة لاكمال البيانات"
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else {
          localStorage.setItem("lu7ah_token", res?.data?.token);
          setToken(JSON.stringify(res?.data?.token));
          refetch();
        }
      },
      onError: (err) => {
        const errorMessage =
          err?.response?.data?.message || "حدث خطأ ما، برجاء المحاولة لاحقاً";

        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"خطأ"}
                description={errorMessage}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box>
      <Container>
        <Text
          fontSize="19px"
          fontWeight="bold"
          color="#405fa1"
          mb="25px"
          textAlign="center"
        >
          رقم التحقق
        </Text>

        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={OtpValidation}
          onSubmit={(values) => {
            mutateVerify({
              ...values,
              mobile: userPhone,
              device_token: localStorage.getItem("fcmToken") || "String",
              platform: "web",
            });
          }}
        >
          {(formik) => {
            return (
              <Form>
                <Box w={["100%", "420px", "420px", "420px"]} m="auto">
                  <Flex gap="10px" justifyContent="center" m="auto" dir="ltr">
                    <PinInput
                      size="lg"
                      value={formik.values.otp}
                      onChange={formik.handleChange("otp")}
                      onBlur={formik.handleBlur("otp")}
                      isInvalid={formik.errors.otp && formik.touched.otp}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </Flex>

                  <CustomButton
                    name="ارسال"
                    mt="50px"
                    mb="10px"
                    type="submit"
                    display="flex"
                    alignItems="center"
                    w={["100%", "81%", "81%", "81%"]}
                    mx="auto"
                    loading={loadingData || isLoadingVerify}
                  />
                  {/* <Text textAlign="center">
                    {" "}
                    ليس لديك حساب؟
                    <Box
                      fontWeight="bold"
                      color="#007bff"
                     
                    >
                      اضغط هنا لاعادة الارسال
                    </Box>
                  </Text> */}
                </Box>
                {/* <Box id="request-recaptcha"></Box> */}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Box>
  );
};

export default ConfirmOtp;
