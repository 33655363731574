import { useState, useEffect } from "react";
import {
  Box,
  Text,
  useToast,
  InputGroup,
  InputLeftElement,
  Image,
  Input,
  Flex,
} from "@chakra-ui/react";
import {
  Container,
  CustomInput,
  CustomButton,
  ErrorMsg,
  CustomToast,
} from "components/shared";
import { Formik, Form } from "formik";
import { signinValidation } from "validations/signin";

import { Link, useNavigate, Outlet } from "react-router-dom";
import { useMutation } from "react-query";
import ConfirmOtp from "./confirm-otp";
import { requestOtp } from "apis/user";
import flag from "assets/images/flag.png";
import { requestPermission } from "../firebase";

const SignIn = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isOtp, setIsOtp] = useState(false);
  const [userPhone, setUserPhone] = useState(null);

  useEffect(() => {
    requestPermission();
  }, []);

  const { mutate, isLoading } = useMutation(requestOtp, {
    onSuccess: () => {
      setIsOtp(true);
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="ارسال رمز التحقق"
              description="تم ارسال رمز التحقق بنجاح "
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: (err) => {
      const errorMessage =
        err?.response?.data?.message || "حدث خطأ ما، برجاء المحاولة لاحقاً";

      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"حدث خطأ"}
              description={errorMessage}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  if (!isOtp) {
    return (
      <>
        <Box>
          <Container>
            <Text
              fontSize="19px"
              fontWeight="bold"
              color="#405fa1"
              mb="25px"
              textAlign="center"
            >
              تسجيل الدخول
            </Text>

            <Formik
              initialValues={{
                phone: "",
              }}
              validationSchema={signinValidation}
              onSubmit={(values) => {
                mutate(values);
                setUserPhone(values.phone);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <Box w={["95%", "85%", "60%", ""]} m="auto">
                      <Text mb="10px">رقم الجوال </Text>
                      <Flex
                        alignItems="center"
                        border="1px solid #e7e7e7"
                        borderRadius="5px"
                        pe="10px"
                      >
                        <Input
                          type="tel"
                          border="none"
                          placeholder="5123 45678"
                          value={formik.values.phone}
                          onChange={formik.handleChange("phone")}
                          onBlur={formik.handleBlur("phone")}
                          _focus={{ boxShadow: "none" }}
                        />
                        <Image src={flag} w="40px" />
                      </Flex>

                      <Text fontSize="12px">مثال : 5xxxxxxx</Text>
                      {formik.errors.phone && formik.touched.phone && (
                        <ErrorMsg text={formik.errors.phone} />
                      )}
                      <CustomButton
                        name="تسجيل"
                        loading={isLoading}
                        mt="50px"
                        mb="10px"
                        type="submit"
                      />
                      <Text textAlign="center">
                        {" "}
                        ليس لديك حساب؟
                        <Box
                          as={Link}
                          to="/signup"
                          fontWeight="bold"
                          color="#007bff"
                        >
                          {" "}
                          تسجيل حساب جديد
                        </Box>
                      </Text>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Container>
        </Box>

        <Box id="request-recaptcha"></Box>
      </>
    );
  } else {
    return (
      <>
        <ConfirmOtp userPhone={userPhone} />
        <Box id="request-recaptcha"></Box>
      </>
    );
  }
};

export default SignIn;
