import { useState, memo, useCallback, useContext } from "react";
import { selectionsContext } from "contexts/selections";
import {
  Box,
  Text,
  Grid,
  Textarea,
  Flex,
  Checkbox,
  Link as ChakraLink,
  useToast,
  Spinner,
  Input,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {
  Container,
  CustomSelect,
  CustomInput,
  CustomButton,
  ErrorMsg,
  CustomToast,
} from "components/shared";
import { useMutation } from "react-query";
import { createList } from "apis/lu7ah";
import { useNavigate } from "react-router-dom";
import saudiLogo from "assets/images/saudi-logo.png";

import lu7ahImage from "assets/images/LU7AH.svg";

// import {
//   typeSelection,
//   numberTypeSelections,
//   costType,
//   cities,
//   transportCost,
// } from "components/shared/selections";

import { LuhahInfo, PrivacyModal } from "components/add-adds";
import { Formik, Form, FieldArray } from "formik";
import { addAddValidation } from "validations/add-ads";

const AddAd = memo(() => {
  const select = { name: "اختر", id: "" };
  const user = JSON.parse(localStorage.getItem("lu7ah_user"));

  const navigate = useNavigate();

  const { type, number_type, cities, cost_transfer, chars_ar_en, numbers } =
    useContext(selectionsContext) || {};

  const chars_arabic = chars_ar_en && Object.keys(chars_ar_en);

  let numberSelectionsArray = [];

  numbers?.map((e) => {
    numberSelectionsArray.push({ id: e, name: e });
  });

  const chars = chars_arabic?.map((e, i) => {
    return { name: e, id: e };
  });

  const toast = useToast();

  //const [showPrivacy, setShowPrivacy] = useState(false);

  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [showPhone, setShowPhone] = useState(true);

  const [selectType, setSelectType] = useState("");
  const [selectNumberType, setSelectNumberType] = useState(select);
  const [selectCity, setSelectCity] = useState("");
  const [selectTransport, setSelectTransport] = useState("");

  const [isPriceEnter, setIsPriceEnter] = useState(true);

  const handleSelectType = (e, formik) => {
    setSelectType(e);
    formik.setFieldValue("type", e.id);
  };

  const handleSelectNumberType = useCallback((e, formik) => {
    formik.setFieldValue("numberType", e.id);

    let numbersArr = [];
    for (let i = 0; i < e?.id; i++) {
      numbersArr.push("");
    }

    formik.setFieldValue("numbersList", numbersArr);

    setSelectNumberType(e);
  }, []);

  const handleSelectCity = (e, formik) => {
    setSelectCity(e);
    formik.setFieldValue("city", e.id);
  };

  const handleSelectTransport = (e, formik) => {
    setSelectTransport(e);
    formik.setFieldValue("cost", e.id);
  };

  // const handleSelectCostType = (e) => {
  //   setSelectCostType(e);
  // };

  const { mutate, isSuccess, isLoading } = useMutation(
    "create-list",
    createList,
    {
      onSuccess: () => {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"اضافة لوحة"}
                description="تم اضافة اللوحة بنجاح"
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
        navigate("/");
      },
      onError: (err) => {
        const errorMessage =
          err?.response?.data?.message || "حدث خطأ ما، برجاء المحاولة لاحقاً";

        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"اضافة لوحة"}
                description={errorMessage}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const selections = [{ name: "1", id: "2" }];

  const SelectNumbersLetters = memo(
    ({ selectionsList, handelChange, selectedValue, isInValid }) => {
      const [selected, setSelected] = useState(
        selectedValue || { name: "", id: null }
      );

      return (
        <Menu>
          <MenuButton as={Box} cursor="pointer">
            <Text
              fontWeight="bold"
              border="1px solid gray"
              borderColor={isInValid ? "red" : "gray"}
              borderRadius="5px"
              textAlign="center"
              py="8px"
              minH="42px"
              // px={["0", "10px", "10px", "10px"]}
              cursor="pointer"
              w={["25px", "35px", "35px", "35px"]}>
              {selected?.name}
            </Text>
          </MenuButton>
          <MenuList
            w="auto"
            minW="40px"
            maxH="170px"
            overflow="auto"
            sx={{
              "::-webkit-scrollbar": {
                width: "8px", // width of the scrollbar
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // color of the scroll thumb
                borderRadius: "4px", // round the edges of the thumb
              },
              "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555", // darker color on hover
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1", // background of the scroll track
              },
            }}>
            {selectionsList?.map((e) => {
              return (
                <MenuItem
                  justifyContent="center"
                  onClick={() => {
                    setSelected(e);
                    handelChange(e);
                  }}>
                  {e?.name}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      );
    }
  );

  return (
    <Box>
      <Container>
        <Text fontSize="19px" fontWeight="bold" color="#405fa1" mb="25px">
          إضافة اعلان جديد
        </Text>
        <Formik
          initialValues={{
            type: selectType?.id,
            numberType: selectNumberType?.id || 4,
            city: selectCity?.id,
            cost: 1,
            phone: user?.mobile,
            price: null,
            notes: "",
            letterOne: "",
            letterTwo: "",
            letterThree: "",
            numbersList: [],
          }}
          validationSchema={addAddValidation}
          onSubmit={(values, formik) => {
            if (isPriceEnter && values.price == null) {
              toast({
                render: (props) => {
                  return (
                    <CustomToast
                      title={"اضافة سعر"}
                      description="يجب ادخال سعر"
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else if (isPriceEnter && values.price == 0) {
              toast({
                render: (props) => {
                  return (
                    <CustomToast
                      title={"اضافة سعر"}
                      description=" لا يمكن لسعر اللوحة أن يكون 0 ريال"
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else if (isPriceEnter && values.price < 1000) {
              toast({
                render: (props) => {
                  return (
                    <CustomToast
                      title={"اضافة سعر"}
                      description="لا يمكن لسعر اللوحة أن يكون أقل من 1000 ريال"
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else {
              mutate({
                type: values.type,
                char1: values.letterOne,
                char2: values.letterTwo,
                char3: values.letterThree,
                num1: values.numbersList[0].toString()[0],
                num2: values.numbersList[1]
                  ? values.numbersList[1].toString()[0]
                  : "",
                num3: values.numbersList[2]
                  ? values.numbersList[2].toString()[0]
                  : "",
                num4: values.numbersList[3]
                  ? values.numbersList[3].toString()[0]
                  : "",
                city: values.city,
                mobile: values.phone,
                price: values.price,
                comment: values.notes,
                cost_transfer: values.cost,
                is_active: true,
                number_type: values.numberType,
                is_hidden: false,
                hidden_my_number: showPhone ? 0 : 1,
                is_sold: false,
                under_bidding: !isPriceEnter,
                is_special: true,
              });
            }
          }}>
          {(formik) => {
            return (
              <Form>
                <Grid
                  gridTemplateColumns={[
                    "repeat(1,1fr)",
                    "repeat(1,1fr)",
                    "repeat(2,1fr)",
                    "repeat(2,1fr)",
                  ]}
                  gap="25px">
                  <Box order={["2", "", "1", ""]}>
                    <Box mb="25px">
                      <CustomSelect
                        label="المدينة"
                        placeholder="اختر مدينة"
                        select={selectCity}
                        handleSelect={(e) => {
                          handleSelectCity(e, formik);
                        }}
                        selections={cities}
                        colorLabel="black"
                      />
                      {formik.errors.city && formik.touched.city && (
                        <ErrorMsg text={formik.errors.city} />
                      )}
                    </Box>

                    <Text mb="5px" opacity={!isPriceEnter ? "0.4" : "1"}>
                      ادخل السعر
                    </Text>
                    <Flex
                      alignItems="flex-start"
                      mb="25px"
                      flexWrap={["wrap", "nowrap", "", ""]}>
                      <Box
                        w={["100%", "60%", "", ""]}
                        me={["0", "15px", "", ""]}
                        mb={["0", "0", "", ""]}>
                        <CustomInput
                          placeholder="بالريال السعودي"
                          type="number"
                          min="0"
                          opacity={!isPriceEnter ? "0.4" : "1"}
                          disabled={!isPriceEnter}
                          value={formik.values.price}
                          onChange={formik.handleChange("price")}
                          onBlur={formik.handleBlur("price")}
                        />
                        {formik.errors.price && formik.touched.price && (
                          <ErrorMsg text={formik.errors.price} />
                        )}
                      </Box>

                      <Flex alignItems="center" mt="6px">
                        <Checkbox
                          onChange={(e) => {
                            setIsPriceEnter(!isPriceEnter);
                            formik.setFieldValue("price", "");
                          }}
                          me="10px"
                          _focus={{ boxShadow: "none" }}
                          _active={{}}
                        />
                        <Text>
                          السعر على{" "}
                          <Text as="span" color="#007bff">
                            السوم
                          </Text>
                        </Text>
                      </Flex>
                    </Flex>

                    <Box mb="25px">
                      <Text textTransform="capitalize" mb="10px">
                        ملاحظات
                      </Text>
                      <Textarea
                        h="150px"
                        resize="none"
                        _focus={{
                          border: "1px solid #a5c6d5",
                          boxShadow: "none",
                        }}
                        value={formik.values.notes}
                        onChange={formik.handleChange("notes")}
                        onBlur={formik.handleBlur("notes")}
                      />
                    </Box>

                    {/* <Flex alignItems="center">
                  <Checkbox
                    me="10px"
                    onChange={() => setAgreePrivacy(!agreePrivacy)}
                    _focus={{ boxShadow: "none" }}
                    _active={{}}
                  />
                  <Text>
                    الموافقة على{" "}
                    <ChakraLink
                      href="https://www.policy.aerbag.com/"
                      target="_blank"
                      // onClick={() => setShowPrivacy(true)}
                      color="#007bff"
                      cursor="pointer"
                    >
                      شروط موقع لوحة
                    </ChakraLink>
                  </Text>
                </Flex> */}

                    <Flex alignItems="center" mt="6px" mb="25px">
                      <Checkbox
                        isChecked={showPhone}
                        onChange={(e) => {
                          setShowPhone(!showPhone);
                        }}
                        me="10px"
                        _focus={{ boxShadow: "none" }}
                        _active={{}}
                      />
                      <Text>اظهار رقم الهاتف</Text>
                    </Flex>

                    <CustomButton
                      name="اضافة"
                      mt="30px"
                      mb="60px"
                      display="flex"
                      alignItems="center"
                      type="submit"
                      loading={isLoading}
                    />
                  </Box>
                  <Box order={["1", "1", "2", ""]}>
                    <Box
                      border="3px solid #f3f3f3 "
                      p="15px"
                      borderRadius="10px">
                      <Grid
                        gridTemplateColumns={[
                          "repeat(1,1fr)",
                          "repeat(2,1fr)",
                          "",
                          "",
                        ]}
                        gap="25px"
                        mb="25px">
                        <Box>
                          <CustomSelect
                            label="نوع اللوحة"
                            placeholder="اختر"
                            select={selectType}
                            handleSelect={(e) => handleSelectType(e, formik)}
                            selections={type}
                            colorLabel="black"
                          />
                          {formik.errors.type && formik.touched.type && (
                            <ErrorMsg text={formik.errors.type} />
                          )}
                        </Box>

                        <Box>
                          <CustomSelect
                            label="نوع الارقام"
                            placeholder="اختر"
                            select={selectNumberType}
                            handleSelect={(e) => {
                              handleSelectNumberType(e, formik);
                            }}
                            selections={number_type}
                            colorLabel="black"
                          />
                          {formik.errors.numberType &&
                            formik.touched.numberType && (
                              <ErrorMsg text={formik.errors.numberType} />
                            )}
                        </Box>
                      </Grid>

                      <Flex
                        border="2px solid black"
                        bg="#f9f9f9"
                        borderRadius="5px">
                        <Box
                          textAlign="center"
                          p="5px"
                          textTransform="uppercase"
                          fontSize="14px"
                          w="45px"
                          bg={
                            formik.values?.type == 1 || !formik.values?.type
                              ? "white"
                              : "#27ADF5"
                          }
                          borderRadius="0 2px 2px 0">
                          <Image
                            src={saudiLogo}
                            w="18px"
                            h="18px"
                            m="auto"
                            mb="5px"
                          />
                          <Text
                            fontSize={["20px", "20px", "20px", ""]}
                            fontWeight="bold">
                            k
                          </Text>
                          <Text
                            fontSize={["20px", "20px", "20px", ""]}
                            fontWeight="bold">
                            s
                          </Text>
                          <Text
                            fontSize={["20px", "20px", "20px", ""]}
                            fontWeight="bold">
                            a
                          </Text>
                          <Box
                            w="8px"
                            h="8px"
                            borderRadius="50%"
                            bg="black"
                            m="auto"
                            mt="2px"
                          />
                        </Box>

                        <Flex pos="relative" w={["100%", "", "90%", ""]}>
                          <Box borderInline="2px solid black" w="40%">
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              gap={["5px", "15px", "15px", "15px"]}
                              w="100%"
                              h="50%"
                              borderBottom="2px solid black">
                              <SelectNumbersLetters
                                selectionsList={chars}
                                handelChange={(e) =>
                                  formik.setFieldValue("letterOne", e?.id)
                                }
                                selectedValue={{
                                  name: formik?.values?.letterOne,
                                  id: formik?.values?.letterOne,
                                }}
                                isInValid={
                                  formik?.errors?.letterOne &&
                                  formik?.touched?.letterOne
                                }
                              />
                              <SelectNumbersLetters
                                selectionsList={chars}
                                handelChange={(e) =>
                                  formik.setFieldValue("letterTwo", e?.id)
                                }
                                selectedValue={{
                                  name: formik?.values?.letterTwo,
                                  id: formik?.values?.letterTwo,
                                }}
                                isInValid={
                                  formik?.errors?.letterTwo &&
                                  formik?.touched?.letterTwo
                                }
                              />
                              <SelectNumbersLetters
                                selectionsList={chars}
                                handelChange={(e) =>
                                  formik.setFieldValue("letterThree", e?.id)
                                }
                                selectedValue={{
                                  name: formik?.values?.letterThree,
                                  id: formik?.values?.letterThree,
                                }}
                                isInValid={
                                  formik?.errors?.letterThree &&
                                  formik?.touched?.letterThree
                                }
                              />
                            </Flex>

                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              gap={["5px", "15px", "15px", "15px"]}
                              w="100%"
                              h="50%">
                              <Text
                                textAlign="center"
                                fontWeight="bold"
                                py="8px"
                                minH="42px"
                                px="10px"
                                w={["auto", "35px", "35px", "35px"]}>
                                {chars_ar_en[formik?.values?.letterOne]}
                              </Text>

                              <Text
                                textAlign="center"
                                fontWeight="bold"
                                py="8px"
                                minH="42px"
                                px="10px"
                                w={["auto", "35px", "35px", "35px"]}>
                                {chars_ar_en[formik?.values?.letterTwo]}
                              </Text>

                              <Text
                                textAlign="center"
                                fontWeight="bold"
                                py="8px"
                                minH="42px"
                                px="10px"
                                w={["auto", "35px", "35px", "35px"]}>
                                {chars_ar_en[formik?.values?.letterThree]}
                              </Text>
                            </Flex>
                          </Box>
                          <Grid placeItems="center" w="60%" dir="ltr">
                            <FieldArray name="numbersList">
                              {({ push, remove }) => (
                                <Flex
                                  justifyContent="center"
                                  gap={["5px", "15px", "15px", "15px"]}>
                                  {formik.values.numbersList.map((n, i) => {
                                    const text = `numbersList[${i}]`;

                                    return (
                                      <SelectNumbersLetters
                                        selectionsList={numberSelectionsArray}
                                        handelChange={(e) =>
                                          formik.setFieldValue(text, e?.id)
                                        }
                                        selectedValue={{ name: n, id: n }}
                                        isInValid={
                                          formik?.errors?.numbersList?.[i] &&
                                          formik?.touched?.numbersList?.[i]
                                        }
                                      />
                                    );
                                  })}
                                </Flex>
                              )}
                            </FieldArray>
                          </Grid>
                          <Grid
                            pointerEvents="none"
                            pos="absolute"
                            w="100%"
                            h="100%"
                            placeItems="center">
                            <Image
                              src={lu7ahImage}
                              opacity="0.1"
                              w={["210px", "", "210px", "230px"]}
                            />
                          </Grid>
                        </Flex>
                      </Flex>
                    </Box>
                  </Box>
                </Grid>

                {/* <PrivacyModal show={showPrivacy} setShow={setShowPrivacy} /> */}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Box>
  );
});

export default AddAd;
